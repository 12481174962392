import React, { useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import accessibility from 'highcharts/modules/accessibility'
HC_more(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
accessibility(Highcharts)


export default function ColumnChart(props) {
    const { chartData, plotData } = props;
    console.log(chartData)
    const chartRef = useRef(null);
    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: chartData.name && chartData.name.length !== 0 ? 'Daily Report ( ' + chartData.name + ' )' : 'Daily Report',
            align: 'left',
            y: 20,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        xAxis: {
            categories: chartData.date_final,
            crosshair: true,
            type: 'datetime',
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%b %e', this.value);
                }
            },
            tickAmount: 10,
            tickPositioner: function () {
                var extremes = this.getExtremes();
                var min = Math.floor(extremes.min);
                var max = Math.ceil(extremes.max);
                var dateDiff = max - min;
                var positions = [];

                if (dateDiff <= 10) {
                    // If 10 or fewer days, show all dates
                    for (var i = 0; i <= dateDiff; i++) {
                        positions.push(min + i);
                    }
                } else {
                    // If more than 10 days, show start, end, and evenly distributed dates
                    // positions.push(min); // Start date

                    var step = Math.floor(dateDiff / 9); // Divide the range into 8 segments
                    for (var i = dateDiff - 1 ; i > 0; i--) {
                        positions.push(max - i * step);
                    }

                    positions.push(max); // End date
                }

                return positions;
            },
            tickMarkPlacement: 'on',
        },
        yAxis: {
            title: {
                text: 'Volume'
            },
            plotLines: [{
                value: 0,
                width: 2,
                color: 'grey',
                dashStyle: 'dash'
            }]
        },
        tooltip: {
            formatter: function () {
                const date = new Date(this.key);
                const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                const weekday = weekdays[date.getUTCDay()];
                const month = months[date.getUTCMonth()];
                const day = date.getUTCDate();
                const year = date.getUTCFullYear();

                var formattedDate = `${weekday}, ${month} ${day}, ${year}`;
                var tooltip = '<b>' + formattedDate + '</b><br/>';
                tooltip += '<b>' + this.point.y + '<br/>';
                tooltip += '<b>' + this.point.percentage + '%<br/>';
                // console.log(this.key)
                // tooltip += '<span style="color:{this.point.color}">{series.name}</span>: <b>{this.point.y:,.0f}</b> ({this.point.percentage:,.0f}%)<br/>'
                // if (this.key === 'Positive' || this.key === 'Negative' || this.key === 'Neutral') {
                //     tooltip += '<span style="color:' + '">\u25CF</span> ' + '<b> Count: ' + this.point.y.toFixed(2) + '</b><br/>';
                // } else {
                //     const sentiment = chartData.sentiments.at(this.point.x).y
                //     const price = chartData.prices.at(this.point.x)
                //     if (sentiment) {
                //         if (sentiment > 0) {
                //             tooltip += '<span style="color:#73BF69">\u25CF</span> ' + '<b> Sentiment: ' + sentiment.toFixed(2) + '</b><br/>';
                //         } else {
                //             tooltip += '<span style="color:#F2495C">\u25CF</span> ' + '<b> Sentiment: ' + sentiment.toFixed(2) + '</b><br/>';
                //         }
                //     }
                //     if (price) {
                //         tooltip += '<span style="color:#5794F2">\u25CF</span> ' + '<b> Price: ' + price.toFixed(2) + '</b><br/>';
                //     }
                // }
                return tooltip;
            }
        },
        // tooltip: {
        //     pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:,.0f}</b> ({point.percentage:,.0f}%)<br/>',
        //     shared: true
        // },
        plotOptions: {
            column: {
                stacking: 'normal',
                pointWidth: 10,

            }
        },
        series: [{
            name: 'Positive',
            data: chartData.positive_final,
            color: '#73bf69',
            showInLegend: false,
        }, {
            name: 'Neutral',
            data: chartData.nutral_final,
            color: '#9b9b9b',
            showInLegend: false,
        }, {
            name: 'Negative',
            data: chartData.negative_final,
            color: '#f2495c',
            showInLegend: false,
        }],
        credits: {
            enabled: false
        },
        lang: {
            noData: "No data for the period selected"
        },
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            containerProps={{ style: { width: '98%' } }}
        /> 
    );
}