import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import '../../assets/css/accounts-page.css';
import {sendMasterArchiveApi} from '../../aePortalApi';
import {countryList, regionList} from "../../constants";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {getTodayDate} from "../../utils";
import {styled} from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '40px',
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiInputBase-root': {
        height: '40px',
    },
});

const CreateFileForm = ({accountId, setRefreshCounter}) => {
    const [entitlement, setEntitlement] = React.useState("equity");
    const [checkedSource, setCheckedSource] = React.useState(new Set());
    const [countries, setCountries] = React.useState([]);
    const [regions, setRegions] = React.useState([]);
    const [countryInputValue, setCountryInputValue] = React.useState('');
    const [regionInputValue, setRegionInputValue] = React.useState('');


    const [disableMasterBtn, setDisableMasterBtn] = React.useState(false);

    const [apiResponseText, setApiResponseText] = React.useState("")
    const [responseStatus, setResponseStatus] = React.useState(0)

    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState(getTodayDate());
    const [sendEmailFlag, setSendEmailFlag] = React.useState(true);


    const sourceHandleChange = (source) => {
        let newCheckedSource = new Set(checkedSource);
        if (checkedSource.has(source)) {
            newCheckedSource.delete(source);
        } else {
            newCheckedSource.add(source);
        }
        setCheckedSource(new Set(newCheckedSource));
    };

    const sendMasterArchive = async () => {
        setDisableMasterBtn(true)
        // if start date and end date is not of the format yyyy-mm-dd
        if (!startDate.match(/^\d{4}-\d{2}-\d{2}$/) || !endDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            // check if start date is a valid date
            setResponseStatus(-1)
            setApiResponseText("ERROR : Start and End date should be of the format yyyy-mm-dd")
            setDisableMasterBtn(false)
            return
        }
        // difference between start and end date should be more than 7 days
        if (new Date(endDate) - new Date(startDate) < 691200000) {
            console.log('difference : ', new Date(endDate) - new Date(startDate))
            setResponseStatus(-1)
            setApiResponseText("ERROR : Difference between start and end date should be more than 7 days")
            setDisableMasterBtn(false)
            return
        }
        const request = {
            "account_id": accountId,
            "entitlement": entitlement,
            "sources": entitlement !== 'earnings_transcript' ? Array.from(checkedSource) : [],
            "countries": countries,
            "regions": regions,
            "start_date": startDate,
            "end_date": endDate,
            "send_email": sendEmailFlag
        }
        const response = await sendMasterArchiveApi(request);
        if (response['status'] === 1) {
            setResponseStatus(1)
            setApiResponseText("Generating Archive : " + response['response']['file_status'])
        } else {
            setResponseStatus(-1)
            if (response['message'] !== undefined && response['message'].includes("token")) {
                setApiResponseText("ERROR : " + response['message'] + " Please login again.")
            } else {
                setApiResponseText("ERROR : " + response['message'])
            }
        }
        setRefreshCounter(prev => prev + 1)
        setDisableMasterBtn(false)
    }

    return (
        <Box sx={{
            padding: '20px',
            borderRadius: '8px',
            marginBottom: '20px',
            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
        }}>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px'}}>
                <FormControl sx={{minWidth: 200, flex: 1}}>
                    <InputLabel id="entitlement-label">Entitlement</InputLabel>
                    <Select
                        labelId="entitlement-label"
                        value={entitlement}
                        label="Entitlement"
                        onChange={(e) => setEntitlement(e.target.value)}
                        sx={{height: '40px'}}
                    >
                        <MenuItem value={'equity'}>Equity</MenuItem>
                        <MenuItem value={'commodity_v5'}>Commodity V5</MenuItem>
                        <MenuItem value={'economic_index'}>Economic Index</MenuItem>
                        <MenuItem value={'earnings_transcript'}>Earnings Transcript</MenuItem>
                        <MenuItem value={'ftri'}>FTRI</MenuItem>
                    </Select>
                </FormControl>
                <StyledTextField
                    label="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    helperText="YYYY-MM-DD"
                    sx={{flex: 1}}
                />
                <StyledTextField
                    label="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    helperText="YYYY-MM-DD"
                    sx={{flex: 1}}
                />
            </Box>

                {entitlement !== 'earnings_transcript' && entitlement !== 'ftri' && (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginTop: '16px', marginLeft: '5px'}}>
                        {['DN', 'BNZ', 'MDT', 'PR'].map((source, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={checkedSource.has(source)}
                                        onChange={() => sourceHandleChange(source)}
                                        sx={{
                                            color: '#6a5acd',
                                            '&.Mui-checked': {
                                                color: '#6a5acd',
                                            },
                                            padding: '4px'
                                        }}
                                    />
                                }
                                label={source}
                            />
                        ))}
                    </Box>
                )}

            {(entitlement === 'equity' || entitlement === 'earnings_transcript') && (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginTop: '16px'}}>
                    <StyledAutocomplete
                        multiple
                        options={countryList}
                        value={countries}
                        onChange={(event, newValue) => setCountries(newValue)}
                        inputValue={countryInputValue}
                        onInputChange={(event, newInputValue) => setCountryInputValue(newInputValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Countries" placeholder="multi select"/>
                        )}
                        sx={{flex: 1, minWidth: 200}}
                    />
                    <StyledAutocomplete
                        multiple
                        options={regionList}
                        value={regions}
                        onChange={(event, newValue) => setRegions(newValue)}
                        inputValue={regionInputValue}
                        onInputChange={(event, newInputValue) => setRegionInputValue(newInputValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Regions (Optional)" placeholder=""/>
                        )}
                        sx={{flex: 1, minWidth: 200}}
                    />
                </Box>
            )}

            <div className="flex place-content-between">
                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '16px', marginLeft: '5px'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sendEmailFlag}
                                onChange={() => setSendEmailFlag(prev => !prev)}
                                sx={{
                                    color: '#6a5acd',
                                    '&.Mui-checked': {
                                        color: '#6a5acd',
                                    },
                                    padding: '4px'
                                }}
                            />
                        }
                        label="Send email"
                    />
                </Box>
                <Button
                    variant="contained"
                    onClick={sendMasterArchive}
                    disabled={disableMasterBtn}
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{
                        marginTop: '16px',
                        backgroundColor: '#6a5acd',
                        '&:hover': {
                            backgroundColor: '#5a4abf',
                        },
                    }}
                >
                    Add New File
                </Button>
            </div>
            {apiResponseText && (
                <Box sx={{
                    marginTop: '16px',
                    padding: '12px',
                    borderRadius: '4px',
                    backgroundColor: responseStatus === 1 ? '#e8f5e9' : '#ffebee',
                    color: responseStatus === 1 ? '#2e7d32' : '#c62828',
                }}>
                    {apiResponseText}
                </Box>
            )}
        </Box>
    );
};

export default CreateFileForm;