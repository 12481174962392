import React, {useState, useEffect, useRef} from 'react';
import '../../assets/css/events-page.css';
import {CircularProgress} from '@mui/material';
import ColumnLinePieChart from './ColumnLinePieChart';
import {useDispatch, useSelector} from 'react-redux';
import {
    dashboardSlice,
    selectFilters,
    selectFilterTags,
    selectFilterType,
    selectKeywordList
} from '../../reducers/dashboardSlice';
import {getDatesBetween, getSourcesFromEntitlements} from "../../utils";
import {getDailyReports, getPriceSentimentData, getPriceSentimentData2} from "../../api";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import accessibility from 'highcharts/modules/accessibility'

HC_more(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
accessibility(Highcharts)

export default function PSVChart() {

    const filter = useSelector(selectFilters);
    const filterTags = useSelector(selectFilterTags);
    const filterType = useSelector(selectFilterType);
    const keywords = useSelector(selectKeywordList);

    const dispatch = useDispatch();

    const tickerName = window.location.pathname.split('/')[2];

    const [loading, setLoading] = useState(false);
    const [priceMap, setPriceMap] = useState({});
    const [sentimentMap, setSentimentMap] = useState({});
    const [posVolMap, setPosVolMap] = useState([]);
    const [neutVolMap, setNeutVolMap] = useState([]);
    const [negVolMap, setNegVolMap] = useState([]);
    const [totalVolMap, setTotalVolMap] = useState([]);


    const onColumnClick = (date) => {
        dispatch(dashboardSlice.actions.setFilterType('date'));
        dispatch(dashboardSlice.actions.deleteAllFilterTags());
        dispatch(dashboardSlice.actions.setCompanyDateFilter(date));
    };

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            if (!tickerName) {
                return;
            }

            const requestBody1 = {
                'from': filter.startDate,
                'to': filter.endDate,
                'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                'sentiment_change': filter.mode,
                'tickers': [tickerName],
                'timezone': "UTC"
            }

            const requestBody2 = {
                'start_date': filter.startDate,
                'end_date': filter.endDate,
                'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                'sentiment_change': filter.mode,
                'universe': [tickerName],
                'value': filterTags,
                'is_keyword_search': (filterTags.length === 1 && !keywords.includes(filterTags[0]))
            }
            const {
                priceMap,
                sentimentMap,
                posVolMap,
                neutVolMap,
                negVolMap,
                totalVolMap
            } = await getPriceSentimentData2(requestBody1, requestBody2, filterType);
            setLoading(false)
            setPriceMap(priceMap);
            setSentimentMap(sentimentMap);
            setPosVolMap(posVolMap);
            setNeutVolMap(neutVolMap);
            setNegVolMap(negVolMap);
            setTotalVolMap(totalVolMap)
        }

        fetchData();
    }, [filter, filterTags]);

    const chartRef = useRef(null);

    const maxVolume = () => {
        if (totalVolMap === undefined || totalVolMap.length === 0) {
            console.log('totalVolMap is empty')
            return 0;
        }
        let max_volume = 0
        totalVolMap.forEach(item => {
            const val = item[1]
            if (val > max_volume) {
                max_volume = val
            }
        })
        // round off the max Volume to nearest larger integeer divisible by 10
        max_volume = Math.ceil(max_volume / 10) * 10
        return max_volume
    }


    const options = {
        title: {
            text: 'Sentiment, Volume and Price Trends',
            align: 'left',
            y: 5,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            crosshair: {
                width: 10, // Set the fixed width in pixels
                color: 'rgba(209,211,215,0.25)', // Optional: set the color
                snap: true, // Ensure the crosshair snaps to data points
                // zIndex: 5 // Optional: set the z-index to ensure it appears above other elements
            },
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%b %e', this.value);
                }
            }
        },
        yAxis: [
            {
                title: {
                    text: 'Price',
                    align: 'high',
                    rotation: 0,
                    offset: 0,
                    y: 13,
                    x: 33,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        color: '#3566b2'
                    }
                },
                labels: {
                    style: {
                        color: '#363636',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                    }
                },
                width: 1,
                lineWidth: 1,
                lineColor: '#9b9b9b',
                gridLineWidth: 0,
                tickLength: 5,  // Length of the tick mark in pixels
                tickWidth: 1,   // Width of the tick mark in pixels
            },
            {
                height: '50%',
                title: {
                    text: 'Sentiment',
                    align: 'high',
                    rotation: 0,
                    offset: 0,
                    y: 13,
                    x: -65,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        color: '#2c6e22'
                    }
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: 'grey',
                    dashStyle: 'dash'
                }],
                max: 1,
                min: -1,
                tickInterval: 0.5,
                opposite: true,
                lineWidth: 1,
                lineColor: '#9b9b9b',
                gridLineWidth: 0,
                tickLength: 5,  // Length of the tick mark in pixels
                tickWidth: 1,   // Width of the tick mark in pixels
                labels: {
                    formatter: function () {
                        // Hide label for the maximum value
                        return this.value === -1 ? '' : this.value;
                    },
                    style: {
                        color: '#363636',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                    }
                }
            },
            {
                top: '50%',
                height: '50%',
                title: {
                    text: 'Volume',
                    align: 'high',
                    rotation: 0,
                    offset: 0,
                    y: 13,
                    x: -50,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        color: '#494949'
                    }
                },
                labels: {
                    format: '{value}',
                    style: {
                        color: '#363636',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                    }
                },
                opposite: true,
                max: maxVolume(),
                min: 0,
                lineWidth: 1,
                lineColor: '#9b9b9b',
                gridLineWidth: 0,
                offset: 0,
                plotLines: [{
                    value: maxVolume(),
                    width: 1,
                    color: '#020202',

                    // dashStyle: 'dash'
                }],
                tickInterval: 10,
                tickLength: 5,  // Length of the tick mark in pixels
                tickWidth: 1,   // Width of the tick mark in pixels
            }
        ],
        tooltip: {
            shared: true,
            formatter: function () {
                const date = new Date(this.x);
                const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                const weekday = weekdays[date.getUTCDay()];
                const month = months[date.getUTCMonth()];
                const day = date.getUTCDate();
                const year = date.getUTCFullYear();

                let formattedDate = `${weekday}, ${month} ${day}, ${year}`;
                let tooltip = '<b>' + formattedDate + '</b><br/>';


                priceMap.forEach(item => {
                    if (item[0] === this.x) {
                        tooltip += '<span style="color:#5794F2">\u25CF</span> <b> Price: ' + item[1] + '</b><br/>';
                    }
                })

                sentimentMap.forEach(item => {
                    if (item[0] === this.x) {
                        if (item[1] >= 0) {
                            tooltip += '<span style="color:#73BF69">\u25CF</span> <b> Sentiment: ' + item[1] + '</b><br/>';
                        } else if (item[1] < 0) {
                            tooltip += '<span style="color:#F2495C">\u25CF</span> <b> Sentiment: ' + item[1] + '</b><br/>';
                        }
                    }
                })

                posVolMap.forEach(item => {
                    if (item[0] === this.x) {
                        tooltip += '<span style="color:#73BF69">\u25CF</span> <b> Positive: ' + item[1] + '</b><br/>';
                    }
                })

                neutVolMap.forEach(item => {
                    if (item[0] === this.x) {
                        tooltip += '<span style="color:#9b9b9b">\u25CF</span> <b> Neutral: ' + item[1] + '</b><br/>';
                    }
                })

                negVolMap.forEach(item => {
                    if (item[0] === this.x) {
                        tooltip += '<span style="color:#f2495c">\u25CF</span> <b> Negative: ' + item[1] + '</b><br/>';
                    }
                })
                return tooltip;
            }
        },
        plotOptions: {
            column: {
                borderRadius: '20%',
                pointWidth: 8,
                stacking: 'normal'
            },
            spline: {
                lineWidth: 2,
                lineColor: '#5794F2',
                marker: {
                    enabled: false
                }
            }
        },
        series: [
            {
                type: 'column',
                name: 'Sentiment',
                data: sentimentMap,
                yAxis: 1,
                zones: [{
                    value: 0,
                    color: '#F2495C',
                    borderColor: '#a11c2b'
                }, {
                    color: '#73BF69',
                    borderColor: '#2c6e22'
                }],
                negativeColor: '#F2495C',
                // borderColor: 'transparent',
                point: {
                    events: {
                        click: function () {
                            let date = new Date(this.x);
                            date = date.toISOString().split('T')[0];
                            onColumnClick(date);
                        }
                    }
                },
                zIndex: 2,
                borderWidth: 1,
                // pointPlacement: -0.1
            },
            {
                type: 'spline',
                name: 'Price',
                data: priceMap,
                yAxis: 0,
                zIndex: 0
            },
            {
                name: 'Positive',
                type: 'column',
                yAxis: 2,
                data: posVolMap,
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ''
                },
                stack: 'volume',
                color: '#73bf69',
                zIndex: 1
            },
            {
                name: 'Neutral',
                type: 'column',
                yAxis: 2,
                data: neutVolMap,
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ''
                },
                stack: 'volume',
                color: '#9b9b9b',
                zIndex: 1
            }, {
                name: 'Negative',
                type: 'column',
                yAxis: 2,
                data: negVolMap,
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ''
                },
                stack: 'volume',
                color: '#f2495c',
                zIndex: 1

            }

        ],
        credits: {
            enabled: false
        },
        exporting: {
            buttons: {
                contextButton: {
                    symbolX: 13,
                    symbolY: 0
                }
            }
        }
        // responsive: {
        //     rules: [{
        //         condition: {
        //             maxWidth: 500
        //         },
        //         chartOptions: {
        //             legend: {
        //                 floating: false,
        //                 layout: 'horizontal',
        //                 align: 'center',
        //                 verticalAlign: 'bottom',
        //                 x: 0,
        //                 y: 0
        //             },
        //             yAxis: [{
        //                 labels: {
        //                     align: 'right',
        //                     x: 0,
        //                     y: -6
        //                 },
        //                 showLastLabel: false
        //             }, {
        //                 labels: {
        //                     align: 'left',
        //                     x: 0,
        //                     y: -6
        //                 },
        //                 showLastLabel: false
        //             }, {
        //                 visible: false
        //             }]
        //         }
        //     }]
        // }
    };


    return (
        <div className='event-chart-full-container mb-2'>
            {loading ? (
                <div className='flex items-center justify-center w-full h-full'>
                    <CircularProgress/>
                </div>
            ) : (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                    containerProps={{style: {width: '98%'}}}
                />
            )}
        </div>
    );
}