import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Autocomplete,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Divider
} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {styled} from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {allTimezones, countryList, hour_strings, minute_strings, regionList} from '../../constants';
import {createSchedule, deleteSchedule, getSchedules, validateCronExpr} from '../../aePortalApi';
import {Tooltip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../assets/css/file-share/schedule.css';
import CustomSingleAutocomplete from "./CustomSingleAutoComplete";
import InfoTooltip from "./FileNameInfo";

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '40px',
    },
});

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputBase-root': {
        height: '40px',
    },
    '& .MuiAutocomplete-inputRoot': {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 14px)',
    },
    '& .MuiAutocomplete-tag': {
        margin: '2px',
    },
    '& .MuiChip-root': {
        height: '24px',
        '& .MuiChip-label': {
            paddingTop: '0',
            paddingBottom: '0',
        },
    }
}));

const Schedules = ({accountId}) => {
    const [schedules, setSchedules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [deletingButtons, setDeletingButtons] = React.useState({});


    const [entitlement, setEntitlement] = useState('equity');
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [folderName, setFolderName] = useState('V4-Daily');
    const [fileNameRegex, setFileNameRegex] = useState(entitlement + '.{end_date}.{endHH}{endMM}.csv');
    const [cronExpr, setCronExpr] = useState('')
    const [explanation, setExplanation] = useState('');
    const [isValid, setIsValid] = useState(null);
    const [tz, setTz] = useState('UTC');
    const [hourDelta, setHourDelta] = useState('00');
    const [minDelta, setMinDelta] = useState('00');
    const [checkedSource, setCheckedSource] = React.useState(new Set());
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    const [createResponse, setCreateResponse] = useState(null);
    const [createApiStatus, setCreateApiStatus] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [countryInputFocused, setCountryInputFocused] = useState(false);


    useEffect(() => {
        fetchSchedules();
    }, [accountId, refreshCounter]);

    useEffect(() => {
        const validateCronExpression = async () => {
            if (cronExpr.split(' ').length === 5) {
                try {
                    const response = await validateCronExpr(cronExpr);
                    if (response.status === 1) {
                        setExplanation(response.response.explanation);
                        setIsValid(true);
                    } else {
                        setExplanation(response.message);
                        setIsValid(false);
                    }
                } catch (error) {
                    setExplanation('Error validating cron expression');
                    setIsValid(false);
                }
            } else {
                setExplanation('');
                setIsValid(null);
            }
        };

        const debounceTimer = setTimeout(validateCronExpression, 500);
        return () => clearTimeout(debounceTimer);
    }, [cronExpr]);

    const fetchSchedules = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await getSchedules(accountId);
            if (response.status === 1) {
                setSchedules(response.response.schedules);
            } else {
                setError('Failed to fetch schedules. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching schedules:', error);
        }
        setIsLoading(false);
    };

    const handleClick = async (event) => {
        setIsLoading(true);
        setIsSuccess(false);
        await handleCreateSchedule(event);
        setIsLoading(false);
        if (createApiStatus === 1) {
            setIsSuccess(true);
            setTimeout(() => setIsSuccess(false), 5000);
        }
        setShowResponse(true);
        setTimeout(() => setShowResponse(false), 5000);
        setTimeout(() => setCreateResponse(null), 5000);
    };

    const handleCreateSchedule = (event) => {
        event.preventDefault();
        const callCreateSchedule = async () => {
            const createScheduleRequest = {
                'account_id': accountId,
                'entitlement': entitlement,
                'sources': [...checkedSource],
                'countries': countries,
                'regions': regions,
                'cron_expression': cronExpr,
                'timezone': tz,
                'timedelta': `${hourDelta}:${minDelta}`,
                'folder_name': folderName,
                'filename_regex': fileNameRegex,
            }

            const response = await createSchedule(createScheduleRequest);
            if (response.status === 1) {
                setRefreshCounter(prev => prev + 1);
                setCreateResponse(response.message)
                setCreateApiStatus(1)
            } else {
                console.error('Error creating schedule:', response.message);
                setCreateResponse(response.message)
                setCreateApiStatus(-1)
            }
        }
        callCreateSchedule();
    };

    const handleDeleteClick = (schedule) => {
        setSelectedSchedule(schedule);
        setOpenDeleteDialog(true);
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
        setSelectedSchedule(null);
    };

    const handleDeleteConfirm = async () => {
        // Implement delete schedule logic here
        setOpenDeleteDialog(false);
        const buttonId = `${selectedSchedule.file_id}-${selectedSchedule.subscription_id}`;
        setDeletingButtons({...deletingButtons, [buttonId]: 'loading'});
        try {
            const response = await deleteSchedule({
                "schedule_id": selectedSchedule.schedule_id,
                "subscription_id": selectedSchedule.subscription_id
            });
            if (response.status === 1) {
                setRefreshCounter(prev => prev + 1);
                setDeletingButtons({...deletingButtons, [buttonId]: 'success'});
            } else {
                setDeletingButtons({...deletingButtons, [buttonId]: 'error'});
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            setDeletingButtons({...deletingButtons, [buttonId]: 'error'});
        }
        setSelectedSchedule(null);
    };

    const sourceHandleChange = (source) => {
        let newCheckedSource = new Set(checkedSource);
        if (checkedSource.has(source)) {
            newCheckedSource.delete(source);
        } else {
            newCheckedSource.add(source);
        }
        setCheckedSource(new Set(newCheckedSource));
    };

    const columns = [
        {field: 'subscription_id', headerName: 'Entitlement', width: 250},
        {field: 'cron_expression', headerName: 'Cron Expr', width: 100},
        {field: 'timezone', headerName: 'Timezone', width: 150},
        {field: 'timedelta', headerName: 'Time Delta', width: 100},
        {field: 'folder_name', headerName: 'Folder Name', width: 100},
        {field: 'latest_file_name', headerName: 'Latest File Name', width: 300},
        {
            field: 'delete_file',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => {
                const buttonId = `${params.row.schedule_id}-${params.row.subscription_id}`;
                const buttonState = deletingButtons[buttonId] || 'default';
                return (
                    <Tooltip title={buttonState === 'success' ? 'File Deleted' : 'Delete File'}>
                    <span>
                        <Button
                            variant='contained'
                            color='error'
                            size='small'
                            onClick={() => handleDeleteClick(params.row)}
                            disabled={buttonState === 'loading'}
                            startIcon={
                                buttonState === 'loading' ? <CircularProgress size={20} color='inherit'/> :
                                    buttonState === 'success' ? <CheckCircleIcon/> : <DeleteIcon/>
                            }
                            sx={{
                                minWidth: '32px',
                                backgroundColor: buttonState === 'success' ? '#9e9e9e' : '#f44336',
                                '&:hover': {
                                    backgroundColor: buttonState === 'success' ? '#8e8e8e' : '#d32f2f',
                                },
                            }}
                        >
                            {buttonState === 'loading' ? 'Deleting' : ''}
                        </Button>
                    </span>
                    </Tooltip>
                );
            },
        }
    ];

    const handleRefresh = () => {
        setIsLoading(true);
        setRefreshCounter(prev => prev + 1);
    };

    return (
        <Box sx={{
            padding: '20px',
        }}>
            <form>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '24px'}}>
                    <FormControl sx={{flex: 1, maxWidth: 200}} required>
                        <InputLabel id='entitlement-label'>Entitlement</InputLabel>
                        <Select
                            labelId='entitlement-label'
                            value={entitlement}
                            label='Entitlement'
                            onChange={(e) => setEntitlement(e.target.value)}
                            sx={{height: '40px'}}
                        >
                            <MenuItem value={'equity'}>Equity</MenuItem>
                            <MenuItem value={'commodity_v5'}>Commodity V5</MenuItem>
                            <MenuItem value={'economic_index'}>Economic Index</MenuItem>
                            <MenuItem value={'earnings_transcript'}>Earnings Transcript</MenuItem>
                            <MenuItem value={'ftri'}>FTRI</MenuItem>
                        </Select>
                    </FormControl>
                    {(entitlement === 'equity' || entitlement === 'earnings_transcript') && (<>
                        <StyledAutocomplete
                            multiple
                            size={'medium'}
                            id="tags-filled"
                            freeSolo
                            options={countryList}
                            sx={{width: 300, margin: '0 8px 0 0'}}
                            onChange={(event, newValue) => setCountries(newValue)}
                            clearOnBlur={true}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Countries'
                                    onFocus={() => setCountryInputFocused(true)}
                                    onBlur={() => setCountryInputFocused(false)}
                                    sx={{input: {color: 'primary', height: '7px'},}}
                                    InputLabelProps={{
                                        shrink: true,
                                        sx: {
                                            position: 'absolute',
                                            top: '0px',
                                            backgroundColor: 'white'
                                        }
                                    }}
                                />}
                        />
                        <CustomSingleAutocomplete options={regionList} onChange={(event, newValue) => setRegions(newValue)} label='Regions' value={regions} width={300}/>
                    </>)}
                    {entitlement !== 'earnings_transcript' && entitlement !== 'ftri' && (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginLeft: '5px'}}>
                            {['DN', 'BNZ', 'MDT', 'PR'].map((source, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={checkedSource.has(source)}
                                            onChange={() => sourceHandleChange(source)}
                                            sx={{
                                                color: '#6a5acd',
                                                '&.Mui-checked': {
                                                    color: '#6a5acd',
                                                },
                                                padding: '4px'
                                            }}
                                        />
                                    }
                                    label={source}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '24px'}}>
                    <StyledTextField
                        label='Folder Name'
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                        sx={{flex: 1, maxWidth: 470}}
                        required
                    />
                    <StyledTextField
                        label='File Name regex'
                        value={fileNameRegex}
                        onChange={(e) => setFileNameRegex(e.target.value)}
                        sx={{flex: 1, maxWidth: 470}}
                        required
                    />
                    <InfoTooltip/>
                </Box>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '8px'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <StyledTextField
                            label='Cron Expression'
                            value={cronExpr}
                            onChange={(e) => setCronExpr(e.target.value)}
                            sx={{flex: 1, maxWidth: 300, input: { color: 'primary', height: '7px' },}}
                            InputLabelProps={{
                                shrink: true,
                                sx: {
                                    position: 'absolute',
                                    top: '0px',
                                    backgroundColor: 'white',
                                    // padding: '0 4px',
                                }
                            }}
                            required
                        />
                        {explanation && (
                            <Typography
                                variant='caption'
                                sx={{
                                    color: isValid ? 'success.main' : 'error.main',
                                    marginTop: '4px'
                                }}
                            >
                                {explanation}
                            </Typography>
                        )}
                    </Box>
                    <CustomSingleAutocomplete required options={allTimezones} onChange={(event, newValue) => setTz(newValue)} label='Timezone' value={tz} width={300}/>
                    <CustomSingleAutocomplete options={hour_strings()} onChange={(event, newValue) => setHourDelta(newValue)} label='Hour Delta' value={hourDelta} width={150}/>
                    <CustomSingleAutocomplete options={minute_strings()} onChange={(event, newValue) => setMinDelta(newValue)} label='Minute Delta' value={minDelta} width={150}/>
                    <Button
                        type='submit'
                        variant='contained'
                        startIcon={isSuccess ? <CheckCircleIcon /> : <AddCircleOutlineIcon />}
                        sx={{
                            backgroundColor: isSuccess ? '#4caf50' : '#6a5acd',
                            '&:hover': {
                                backgroundColor: isSuccess ? '#45a049' : '#5a4abf',
                            },
                            height: '40px'
                        }}
                        onClick={handleClick}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : isSuccess ? (
                            'Created'
                        ) : (
                            'Create Schedule'
                        )}
                    </Button>
                </Box>
                {showResponse && (
                    <Box sx={{
                        padding: '16px',
                        backgroundColor: createApiStatus === 1 ? '#e8f5e9' : '#ffebee',
                        color: createApiStatus === 1 ? '#2e7d32' : '#c62828',
                        borderRadius: '4px',
                        marginTop: '16px',
                        marginBottom: '24px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <InfoIcon sx={{marginRight: '8px'}}/>
                        {createResponse}
                    </Box>
                )}
            </form>
            <Divider sx={{marginBottom: '24px', marginTop: '32px'}}/>

            <div className='schedule-header'>
                <h5>Schedule Details</h5>
                <button onClick={handleRefresh} className='refresh-button'>
                    <RefreshIcon/>
                </button>
            </div>
            {isLoading ? (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px'}}>
                    <CircularProgress/>
                </Box>
            ) : error ? (
                <Box sx={{
                    padding: '16px',
                    backgroundColor: '#ffebee',
                    color: '#c62828',
                    borderRadius: '4px',
                    marginTop: '16px'
                }}>
                    {error}
                </Box>
            ) : (
                <>
                    {schedules && schedules.length > 0 && (
                        <div style={{height: 400, width: '100%'}}>
                            <DataGrid
                                rows={schedules}
                                columns={columns}
                                getRowId={(row) => row.subscription_id + row.cron_expression + row.timezone + row.timedelta}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field: 'account_id', sort: 'asc'}],
                                    },
                                }}
                            />
                        </div>
                    )}

                    {schedules.length === 0 && (
                        <div className='api-response-text error'>No schedules found for this account</div>
                    )}
                </>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteCancel}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Confirm Delete'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to delete this schedule?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Schedules;