import React from 'react';
import { Tooltip, Typography, Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';

const StyledInfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    '&:hover': {
        color: theme.palette.primary.dark,
    },
    marginLeft: '-8px'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '4px 8px', // Reduced padding
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontSize: '0.75rem', // Smaller font size
    '&:first-of-type': {
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        maxWidth: 300, // Reduced max width
        fontSize: theme.typography.pxToRem(12), // Smaller font size
        border: '1px solid #dadde9',
        boxShadow: theme.shadows[1], // Reduced shadow
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1), // Reduced padding
    },
}));

const InfoTooltip = () => {
    const start_time = '2024-09-18 17:25:00';
    const end_time = '2024-09-19 18:30:00';

    const tooltipContent = (
        <Box sx={{ p: 1 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'primary.main', mb: 0.5 }}>
                Available Patterns
            </Typography>
            <Typography variant="caption" sx={{ mb: 1, fontStyle: 'italic', display: 'block' }}>
                If start time is {start_time} and end time is {end_time}:
            </Typography>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        {[
                            { pattern: 'start_date', value: '2024-09-18' },
                            { pattern: 'startHH', value: '17' },
                            { pattern: 'startMM', value: '25' },
                            { pattern: 'end_date', value: '2024-09-19' },
                            { pattern: 'endHH', value: '18' },
                            { pattern: 'endMM', value: '30' },
                            { pattern: 'start_time', value: '2024-09-18.1725' },
                            { pattern: 'end_time', value: '2024-09-19.1830' },
                            { pattern: 'short_start_date', value: '20240918' },
                            { pattern: 'short_end_date', value: '20240919' },
                        ].map(({ pattern, value }, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>{pattern}</StyledTableCell>
                                <StyledTableCell>{value}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );

    return (
        <StyledTooltip title={tooltipContent} arrow placement="right">
            <StyledInfoIcon />
        </StyledTooltip>
    );
};

export default InfoTooltip;