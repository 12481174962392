import React, { useState, useEffect } from 'react';
import { FaUserPlus, FaSearch } from 'react-icons/fa';
import '../../assets/css/file-share/users.css';
import UserDetails from "./UserDetails";
import CreateUserForm from "./CreateUserForm";
import { getAllUsers } from "../../aePortalApi";
import { Dialog } from '@mui/material';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isCreatingUser, setIsCreatingUser] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        getAllUsers().then((response) => {
            if (response['status'] === 1) {
                const sortedUsers = sortUsersByCreatedOn(response['response']['users']);
                setUsers(sortedUsers);
            } else {
                console.log('error fetching users');
            }
        });
    };

    const sortUsersByCreatedOn = (usersArray) => {
        return usersArray.sort((a, b) => {
            return new Date(b.created_on) - new Date(a.created_on);
        });
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredUsers = users.filter(user =>
        user.account_id.toLowerCase().includes(searchTerm.toLowerCase())
        || user.name.toLowerCase().includes(searchTerm.toLowerCase())
        || user.company_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCreateUserClose = () => {
        setIsCreatingUser(false);
    };

    const handleUserCreated = (newUser) => {
        setUsers(prevUsers => sortUsersByCreatedOn([newUser, ...prevUsers]));
        setSelectedUser(newUser);
        setIsCreatingUser(false);
    };

    return (
        <div className="users-container">
            <div className="left-section">
                <button className="create-user-btn" onClick={() => setIsCreatingUser(true)}>
                    <FaUserPlus /> Create User
                </button>
                <div className="search-box">
                    <FaSearch className="search-icon" />
                    <input
                        type="text"
                        placeholder="Search by Account ID/Company/Name"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </div>
                <ul className="user-list">
                    {filteredUsers.map(user => (
                        <li
                            key={user.id}
                            className={`user-item ${selectedUser && selectedUser.id === user.id ? 'active' : ''}`}
                            onClick={() => setSelectedUser(user)}
                        >
                            {user.account_id}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="right-section">
                {selectedUser ? (
                    <UserDetails user={selectedUser} />
                ) : (
                    <div className="no-selection">
                        <p>Select a user from the list or create a new user.</p>
                    </div>
                )}
            </div>
            <Dialog open={isCreatingUser} onClose={handleCreateUserClose}>
                <CreateUserForm onClose={handleCreateUserClose} onUserCreated={handleUserCreated} />
            </Dialog>
        </div>
    );
};

export default Users;