import {baseUrl} from "./constants";
import {ae_portal_post} from "./api";

const adminBaseUrl = `${baseUrl}/cras/api/v1/admin`;
const aePortalBaseUrl = `${baseUrl}/aeportal/api/v1`;

export const get = async (url) => {
    try {
        var requestOptions = {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            const fail_res = await response.json()
            console.log(fail_res)
            alert(fail_res['message'])
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const post = async (url, request_data) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(request_data),
        };
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


export const authPost = async (url, request, authToken) => {
    try {
        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + authToken,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        };

        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            console.error('Network response was not ok.');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const adminLogin = async (username, password) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"username": username, "password": password});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        const response = await fetch(`${baseUrl}/cras/api/v1/login`, requestOptions)
        if (!response.ok) {
            const fail_res = await response.json()
            console.log(fail_res)
            alert(fail_res['message'])
            return {};
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getAuthToken = async () => {
    const username = localStorage.getItem('admin.username');
    const password = localStorage.getItem('admin.password');
    const decryptedUsername = atob(username);
    const decryptedPassword = atob(password);
    const response = await adminLogin(decryptedUsername.toUpperCase(), decryptedPassword);
    if (response !== {} && response['status'] === 1) {
        return response['response']['auth_token']
    } else {
        return ''
    }
}

export const createNewUser = async (request) => authPost(`${adminBaseUrl}/user`, request, await getAuthToken());
export const sendMasterArchiveApi = async (request) => authPost(`${adminBaseUrl}/portal/data`, request, await getAuthToken());

export const getFilesByAccount = async (accountId) => authPost(`${adminBaseUrl}/user/files`, {"account_id": accountId}, await getAuthToken());

export const sendFileEmail = async (fileId, subscriptionId) =>
    authPost(`${adminBaseUrl}/user/files/email`, {"file_id": fileId, "subscription_id": subscriptionId}, await getAuthToken());

export const getAllUsers = async () => get(`${aePortalBaseUrl}/user/all`);


export const createTrialUser = (request) => post(`${aePortalBaseUrl}/user/create/trial`, request)
export const createUserParallel = (request) => post(`${aePortalBaseUrl}/user/create/parallel`, request)
export const upsertUser = (request) => post(`${aePortalBaseUrl}/user/upsert`, request)
export const deleteFile = async (fileid, subscriptionid) => post(`${aePortalBaseUrl}/file/delete`, {'file_id': fileid, 'subscription_id': subscriptionid})

export const getSchedules = async (accountId) => post(`${aePortalBaseUrl}/schedule/user/all`, {"account_id": accountId});

export const validateCronExpr = async (cronExpr) => post(`${aePortalBaseUrl}/schedule/cron/validate`, {"cron_expression": cronExpr})

export const createSchedule = async (request) => post(`${aePortalBaseUrl}/schedule/create`, request);

export const deleteSchedule = async (request) => post(`${aePortalBaseUrl}/schedule/delete`, request);