import React, {useEffect, useRef} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import accessibility from 'highcharts/modules/accessibility'

HC_more(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
accessibility(Highcharts)

const getMaxSentiment = (sentiments) => {
    if (sentiments === undefined || sentiments.length === 0) {
        return 0;
    }
    var max = 0;
    sentiments.forEach((sentiment) => {
        if (Math.abs(sentiment.y) > max) {
            max = Math.abs(sentiment.y);
        }
    });
    return max;
}


export default function ColumnLinePieChart({chartData, onColumnClick}) {
    const max = getMaxSentiment(chartData.sentiments) + 0.2
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;
            if (chart) {
                chart.series[0].points.forEach((point, index) => {
                    point.graphic.element.addEventListener('click', () => {
                        const date = chartData.dates[index];
                        onColumnClick(date);
                    });
                });
            }
        }
    }, [chartData, onColumnClick]);
    const options = {
        title: {
            text: 'Sentiment and Price Chart',
            align: 'left',
            y: 20,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: chartData.dates,
            crosshair: true,
            type: 'datetime',
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%b %e', this.value);
                }
            },
            tickAmount: 10,
            tickPositioner: function () {
                var extremes = this.getExtremes();
                var min = Math.floor(extremes.min);
                var max = Math.ceil(extremes.max);
                var dateDiff = max - min;
                var positions = [];

                if (dateDiff <= 10) {
                    // If 10 or fewer days, show all dates
                    for (var i = 0; i <= dateDiff; i++) {
                        positions.push(min + i);
                    }
                } else {
                    // If more than 10 days, show start, end, and evenly distributed dates
                    // positions.push(min); // Start date

                    var step = Math.floor(dateDiff / 9); // Divide the range into 8 segments
                    for (var i = dateDiff - 1 ; i > 0; i--) {
                        positions.push(max - i * step);
                    }

                    positions.push(max); // End date
                }

                return positions;
            },
            tickMarkPlacement: 'on',
        },
        yAxis: [
            {   // Primary Axis
                title: {
                    text: 'Sentiment'
                },
                plotLines: [{
                    value: 0,
                    width: 2,
                    color: 'grey',
                    dashStyle: 'dash'
                }],
                max: 1,
                min: -1,
                tickInterval: 0.5,
            },
            { // Secondary Axis
                title: {
                    text: 'Price'
                },
                opposite: true
            }],
        tooltip: {
            formatter: function () {
                const date = new Date(this.key);
                const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                const weekday = weekdays[date.getUTCDay()];
                const month = months[date.getUTCMonth()];
                const day = date.getUTCDate();
                const year = date.getUTCFullYear();

                var formattedDate = `${weekday}, ${month} ${day}, ${year}`;
                var tooltip = '<b>' + formattedDate + '</b><br/>';
                if (this.key === 'Positive' || this.key === 'Negative' || this.key === 'Neutral') {
                    tooltip += '<span style="color:' + '">\u25CF</span> ' + '<b> Count: ' + this.point.y.toFixed(2) + '</b><br/>';
                } else {
                    const sentiment = chartData.sentiments.at(this.point.x).y
                    const price = chartData.prices.at(this.point.x)
                    if (sentiment) {
                        if (sentiment > 0) {
                            tooltip += '<span style="color:#73BF69">\u25CF</span> ' + '<b> Sentiment: ' + sentiment.toFixed(2) + '</b><br/>';
                        } else {
                            tooltip += '<span style="color:#F2495C">\u25CF</span> ' + '<b> Sentiment: ' + sentiment.toFixed(2) + '</b><br/>';
                        }
                    }
                    if (price) {
                        tooltip += '<span style="color:#5794F2">\u25CF</span> ' + '<b> Price: ' + price.toFixed(2) + '</b><br/>';
                    }
                }
                return tooltip;
            }
        },
        plotOptions: {
            series: {
                borderRadius: '25%',
                lineWidth: 2,
                lineColor: '#5794F2',
            }
        },
        series: [{
            type: 'column',
            name: 'Sentiment',
            data: chartData.sentiments,
            pointWidth: 10,
        }, {
            type: 'spline',
            name: 'Price',
            data: chartData.prices,
            yAxis: 1,
            marker: {
                enabled: false
            }
        }
        ],
        credits: {
            enabled: false
        }
    }


    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            containerProps={{style: {width: '98%'}}}
        />
    );
}