import React, {useEffect, useState} from 'react';
import '../../assets/css/file-share/directory-tree.css';

const TreeNode = ({name, value}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isFolder = value !== null && typeof value === 'object';

    const toggleExpand = () => {
        if (isFolder) {
            setIsExpanded(!isExpanded);
        }
    };

    return (
        <div className="tree-node">
            <div className={`node-content ${isFolder ? 'folder' : 'file'}`} onClick={toggleExpand}>
                {isFolder && (
                    <span className={`expand-icon ${isExpanded ? 'expanded' : ''}`}>
            {isExpanded ? '▼' : '▶'}
          </span>
                )}
                <span className={`node-icon ${isFolder ? 'folder' : 'file'}`}></span>
                <span className="node-name">{name}</span>
            </div>
            {isExpanded && isFolder && (
                <div className="node-children">
                    {value && Object.entries(value).map(([childName, childValue]) => (
                        <TreeNode key={childName} name={childName} value={childValue}/>
                    ))}
                </div>
            )}
        </div>
    );
};

const DirectoryTree = () => {
    // const [data, setData] = useState({});
    const data = {
        "TAPAN01": {
            "alexandria.sentiment.reddit.crypto.20180101-20241101.csv": null,
            "api": {
                "alexandria.sentiment.equity.DN.20240905.0000-20241023.2359.csv.gz": null,
                "alexandria.sentiment.equity.DN.20000101.0000-20240930.2359.csv.gz": null,
                "alexandria.sentiment.equity.DN.20240901.0000-20241023.2359.csv.gz": null,
                "alexandria.sentiment.earnings_transcript.20240911.0000-20241111.2359.csv.gz": null,
                "alexandria.sentiment.equity.DN.20000101.0000-20241104.2359.csv.gz": null,
                "alexandria.sentiment.equity.DN.20240904.0000-20241023.2359.csv.gz": null,
                "alexandria.sentiment.economic_index.20240101.0000-20241001.2359.csv.gz": null,
                "alexandria.sentiment.equity.DN.20240902.0000-20241023.2359.csv.gz": null,
                "alexandria.sentiment.equity.DN.20240903.0000-20241023.2359.csv.gz": null,
                "alexandria.sentiment.economic_index.20000101.0000-20240717.2359.csv.gz": null,
                "alexandria.sentiment.earnings_transcript.20240811.0000-20241111.2359.csv.gz": null,
                "alexandria.sentiment.equity.DN.20000101.0000-20241101.2359.csv.gz": null
            },
            "V4-Daily": {
                "equity.PRDN.2024-10-04.1000.EDT.csv": null,
                "equity.DNPR.2024-10-27.1000.EDT.csv": null,
                "equity.DNPR.2024-10-13.1000.EDT.csv": null,
                "equity.DNPR.2024-11-02.1000.EDT.csv": null,
                "equity.DNPR.2024-11-06.1000.EST.csv": null,
                "equity.DNPR.2024-09-24.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1105.csv": null,
                "equity.PRDN.2024-09-29.1000.EDT.csv": null,
                "equity.PRDN.2024-09-27.1000.EDT.csv": null,
                "equity.DNPR.2024-09-25.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1420.csv": null,
                "equity.PRDN.2024-10-06.1000.EDT.csv": null,
                "equity.DNPR.2024-09-22.1000.EDT.csv": null,
                "equity.PRDN.2024-10-02.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1500.csv": null,
                "equity.DNPR.2024-09-23.1000.EDT.csv": null,
                "equity.DNPR.2024-11-11.1000.EST.csv": null,
                "equity.DN.2024-09-18.1330.csv": null,
                "equity.DN.2024-09-18.1455.csv": null,
                "equity.DN.2024-09-18.1415.csv": null,
                "equity.DN.2024-09-18.1340.csv": null,
                "equity.DN.2024-09-18.1510.csv": null,
                "equity.DN.2024-09-18.1350.csv": null,
                "equity.DNPR.2024-11-05.1000.EST.csv": null,
                "equity.DNPR.2024-10-31.1000.EDT.csv": null,
                "equity.DNPR.2024-10-16.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1435.csv": null,
                "equity.PRDN.2024-10-09.1000.EDT.csv": null,
                "equity.DNPR.2024-10-10.1000.EDT.csv": null,
                "equity.DNPR.2024-09-20.1000.EDT.csv": null,
                "equity.DNPR.2024-10-17.1000.EDT.csv": null,
                "equity.DNPR.2024-10-18.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1430.csv": null,
                "equity.DNPR.2024-10-21.1000.EDT.csv": null,
                "equity.PRDN.2024-10-08.1000.EDT.csv": null,
                "equity.DNPR.2024-10-28.1000.EDT.csv": null,
                "equity.DNPR.2024-10-25.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1425.csv": null,
                "equity.DN.2024-09-18.1335.csv": null,
                "equity.DN.2024-09-18.1005.csv": null,
                "equity.PRDN.2024-09-30.1000.EDT.csv": null,
                "equity.PRDN.2024-09-28.1000.EDT.csv": null,
                "equity.DNPR.2024-10-29.1000.EDT.csv": null,
                "equity.DNPR.2024-10-20.1000.EDT.csv": null,
                "equity.DNPR.2024-11-12.1000.EST.csv": null,
                "equity.DNPR.2024-10-22.1000.EDT.csv": null,
                "equity.PRDN.2024-10-05.1000.EDT.csv": null,
                "equity.PRDN.2024-10-07.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1410.csv": null,
                "equity.DN.2024-09-18.1505.csv": null,
                "equity.DNPR.2024-11-01.1000.EDT.csv": null,
                "equity.DNPR.2024-11-03.1000.EST.csv": null,
                "equity.DN.2024-09-18.1400.csv": null,
                "equity.PRDN.2024-09-26.1000.EDT.csv": null,
                "equity.DNPR.2024-10-15.1000.EDT.csv": null,
                "equity.DNPR.2024-01-11.0700.EDT.csv": null,
                "equity.DN.2024-09-18.1440.csv": null,
                "equity.DNPR.2024-10-19.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1355.csv": null,
                "equity.DN.2024-09-18.1445.csv": null,
                "equity.DN.2024-09-18.1300.csv": null,
                "equity.DNPR.2024-10-14.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1305.csv": null,
                "equity.DN.2024-09-18.1515.csv": null,
                "equity.DNPR.2024-09-19.1000.EDT.csv": null,
                "equity.DNPR.2024-10-12.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1405.csv": null,
                "equity.DNPR.2024-10-30.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1345.csv": null,
                "equity.DNPR.2024-01-11.0900.EDT.csv": null,
                "equity.DNPR.2024-09-21.1000.EDT.csv": null,
                "equity.PRDN.2024-10-01.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1205.csv": null,
                "equity.DNPR.2024-11-09.1000.EST.csv": null,
                "equity.DNPR.2024-10-23.1000.EDT.csv": null,
                "equity.DNPR.2024-11-08.1000.EST.csv": null,
                "equity.DNPR.2024-10-24.1000.EDT.csv": null,
                "equity.PRDN.2024-10-03.1000.EDT.csv": null,
                "equity.DNPR.2024-10-11.1000.EDT.csv": null,
                "equity.DNPR.2024-10-26.1000.EDT.csv": null,
                "equity.DN.2024-09-18.1450.csv": null,
                "equity.DN.2024-09-18.1310.csv": null,
                "equity.DNPR.2024-11-10.1000.EST.csv": null,
                "equity.PRDN.2024-03-27.1000.EDT.csv": null,
                "equity.DNPR.2024-11-04.1000.EST.csv": null
            }
        }
    }

    return (
        <div className="folder-tree-container">
            <h5>Directory Tree</h5>
            <div className="folder-tree">
                {Object.entries(data).map(([name, value]) => (
                    <TreeNode key={name} name={name} value={value} />
                ))}
            </div>
        </div>
    );
};

export default DirectoryTree;