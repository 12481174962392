import React from 'react';
import '../assets/css/file-share/main-page.css'
import Auth from "../components/file-share-page-2/AuthInputs";
import Users from "../components/file-share-page-2/Users";

const FileSharePage2 = () => {
    const [showAuth, setShowAuth] = React.useState(true);



    return (
        <div className="main-page">
            <main className="content">
                {showAuth && <Auth setShowAuth={setShowAuth}/>}
                {!showAuth && <Users/>}
            </main>
        </div>
    );
};

export default FileSharePage2;