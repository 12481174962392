import {keyToCategoryMapping} from "./constants";

export function areDatesEqual(date1, date2) {
    const year1 = date1.getUTCFullYear();
    const month1 = date1.getUTCMonth();
    const day1 = date1.getUTCDate();

    const dateArr = date2.split("-");
    const year2 = dateArr[0];
    const month2 = dateArr[1];
    const day2 = dateArr[2];

    return year1 === year2 && month1 === month2 && day1 === day2;
}

export function dateToString(date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

// convert string of format YYYY-MM-DD to Date object
export function stringToDate(dateString) {
    const utcDate = new Date(dateString);
    return setUTCTimezone(utcDate);
}

const setUTCTimezone = (date) => {
    if (!date) {
        return date;
    }
    const timezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + timezoneOffset);
    return date;
}

export function getSentimentColor(sentiment) {
    if (sentiment > 0) {
        const red = Math.round(200 * (1 - 3 * sentiment));
        return `rgb(${red}, 255, 0)`;
    } else if (sentiment === 0) {
        return `rgb(128, 128, 128)`;
    } else {
        const green = Math.round(100 * (1 + sentiment));
        return `rgb(255, ${green}, 0)`;
    }
}

export function rgbToHex(rgb) {
    const [r, g, b] = rgb.match(/\d+/g);
    return `#${Number(r).toString(16)}${Number(g).toString(16)}${Number(b).toString(16)}`;
}

export function getColorHardcoded(sentiment) {
    if (sentiment > 0.9) {
        return "#32c207";
    } else if (sentiment <= 0.9 && sentiment > 0.8) {
        return "#49c332";
    } else if (sentiment <= 0.8 && sentiment > 0.7) {
        return "#5cc44b";
    } else if (sentiment <= 0.7 && sentiment > 0.6) {
        return "#6cc55f";
    } else if (sentiment <= 0.6 && sentiment > 0.5) {
        return "#7bc571"
    } else if (sentiment <= 0.5 && sentiment > 0.4) {
        return "#89c683"
    } else if (sentiment <= 0.4 && sentiment > 0.3) {
        return "#97c693"
    } else if (sentiment <= 0.3 && sentiment > 0.2) {
        return "#a5c5a3"
    } else if (sentiment <= 0.2 && sentiment > 0.1) {
        return "#b3c4b2"
    } else if (sentiment <= 0.1 && sentiment > 0) {
        return "#c2d4c1"
    } else if (sentiment === 0) {
        return "#9d9d9d"
    } else if (sentiment < 0 && sentiment > -0.1) {
        return "#c7b1ab"
    } else if (sentiment <= -0.1 && sentiment > -0.2) {
        return "#cca196"
    } else if (sentiment <= -0.2 && sentiment > -0.3) {
        return "#ce9081"
    } else if (sentiment <= -0.3 && sentiment > -0.4) {
        return "#cf7f6c"
    } else if (sentiment <= -0.4 && sentiment > -0.5) {
        return "#ce6e58"
    } else if (sentiment <= -0.5 && sentiment > -0.6) {
        return "#cc5c45"
    } else if (sentiment <= -0.6 && sentiment > -0.7) {
        return "#ca4831"
    } else if (sentiment <= -0.7 && sentiment > -0.8) {
        return "#c6311d"
    } else if (sentiment <= -0.8 && sentiment > -0.9) {
        return "#c10606"
    } else {
        return "#c10606"
    }
}

export function findPercentileRank(arr, value, totalValues) {
    let numBelowValue = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] < value) {
            numBelowValue++;
        }
    }

    let numEqualToValue = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === value) {
            numEqualToValue++;
        }
    }

    return ((numBelowValue + 0.5 * numEqualToValue) / totalValues);

}

export function getUniqueValues(arr) {
    let uniqueValues = [];
    for (let i = 0; i < arr.length; i++) {
        if (!uniqueValues.includes(arr[i])) {
            uniqueValues.push(arr[i]);
        }
    }
    return uniqueValues;
}

export function getNormalizedWeight(weight, min, max) {
    return ((weight - min) / (max - min)) * 100 + 30;
}

export function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export function getDatesBetween(startDate, endDate) {
    let dates = [];
    let currentDate = stringToDate(startDate);
    while (currentDate <= stringToDate(endDate)) {
        dates.push(dateToString(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

export function getPreviousDate(date) {
    let currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() - 1);
    return dateToString(currentDate);
}

export function getShortenedString(str) {
    let words = str.split(" ");
    if (words.length <= 20) {
        return str;
    } else {
        return words.slice(0, 10).join(" ") + "..." + words.slice(words.length - 10).join(" ");
    }
}

export function getSourcesFromEntitlements(entitlments) {
    if (!entitlments) return [];
    let classes = []
    let category = ''
    let subcategory = [];
    if (entitlments.includes('MD') || entitlments.includes('QA')) {
        classes = entitlments.filter(ent => ent !== 'MD' && ent !== 'QA');
        category = 'call'
    }

    if (entitlments.includes('MD')) subcategory.push('MD');
    if (entitlments.includes('QA')) subcategory.push('QA');
    if (entitlments.includes('DJ')) {
        subcategory.push('DJ');
        category = 'news';
    }
    if (entitlments.includes('PR')) {
        subcategory.push('PR');
        category = 'news';
    }
    if (entitlments.includes('WB')) {
        subcategory.push('article');
        classes = ['WB'];
        category = 'webnews';
    }
    return [{
        'category': category,
        'class': classes,
        'subcategory': subcategory
    }]
}

export function getDayConstFromFilters(userFilters) {
    var entitlements = userFilters && userFilters.selectedEnt && typeof
        (userFilters.selectedEnt) != 'string' ? userFilters.selectedEnt : ['E']
    if (entitlements.includes('DJ') || entitlements.includes('PR') || entitlements.includes('WB')) return 8;
    return 90;
}

export function getDayConstFromEntitlement(entitlements) {
    if (entitlements.includes('DJ') || entitlements.includes('PR') || entitlements.includes('WB')) return 8;
    return 90;
}

export function isArrayNotEmpty(arr) {
    return arr && Array.isArray(arr) && arr.length > 0;
}

export function isCallEnt(entArr) {
    return entArr.includes('MD') || entArr.includes('QA');
}

export function isNewsEnt(entArr) {
    return entArr.includes('DJ') || entArr.includes('PR');
}

export function isWebNewsEnt(entArr) {
    return entArr.includes('WB');
}

export function deleteAllCookies() {
    const cookies = document.cookie.split(";");
    console.log("cookies", cookies);
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export function deleteLocalStorage() {
    localStorage.clear();
}

export function getUserNameFromStorage() {
    const username = localStorage.getItem('admin.username');
    if (!username) {
        return '';
    } else {
        return atob(username);
    }
}

export function getPasswordFromStorage() {
    const password = localStorage.getItem('admin.password');
    if (!password) {
        return '';
    } else {
        return atob(password);
    }
}

export function getTodayDate() {
    const today = new Date();
//     get today utc date in format YYYY-MM-DD
    return dateToString(today);
}

export function roundOffNumber(number) {
    const num = Number(number);

    if (isNaN(num)) {
        return "Invalid input";
    }

    return parseFloat(num.toFixed(2));
}

